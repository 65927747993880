import "../../commons/element-ui-css/element-ui.css";
import Vue from "vue";
import Vuex from "vuex";
import LinksLoginView from "./links-login.vue";
import RegistrazioneModel from "./model-base.js";
import DataApi from "./data-api.js";
import lang from "element-ui/lib/locale/lang/it.js";
import locale from "element-ui/lib/locale";

if (!global._babelPolyfill){
  require("babel-polyfill");
}
locale.use(lang);

let api = new DataApi();

Vue.use(Vuex);
/* global process */
const store = new Vuex.Store({
    strict: process.env.NODE_ENV === "development",
    state: {
        nazioni: [],
        province: [],
        model: RegistrazioneModel
    },

    mutations: {
        setNazioni(state, nazioni){
            state.nazioni = nazioni;
        },
        setProvince(state, province){
            state.province = province;
        }
    }
});

new Vue({
  store,
  render: (h) => {
    return h(LinksLoginView);
  }
}).$mount("#add-nuovo-utente");

api.getMeta().then(response => {
  if (response.nazioni){
    store.commit("setNazioni", response.nazioni);
  }
  if (response.province){
    store.commit("setProvince", response.province);
  }
});
