const personaFisica = {
    cognome: null,
    nome: null,
    nazione: null,
    provincia: null,
    citta: null,
    data: null,
    codiceFiscale: null
};

const societa = {
    ragioneSociale: null,
    partitaIVA: null
};

const anagrafica = {
    tipoPersona: "PF",
    personaFisica: personaFisica,
    societa: societa
};

const contatti = {
    indirizzo: null,
    cap: null,
    nazione: null,
    provincia: null,
    citta: null,
    cellulare: null,
    notificheSMS: false,
    email: null,
    pec: null
};

const datiUtente = {
    utente: null,
    password: null,
    verifyPassword: null,
    useSPID: false,
    privacy: false
};

export default {
    anagrafica: anagrafica,
    contatti: contatti,
    datiUtente: datiUtente,
    captcha: null
};
