<template>
  <div>
    <moon-loader :loading="loading" style="position:fixed;top:50%;left:50%;z-index:1099;"></moon-loader>
    <div
      v-show="showBackdrop"
      class="modal-backdrop"
      :class="{ fade: fade, in: animateBackdrop || !fade }"
      style="z-index:9999"
    ></div>
  </div>
</template>

<style scoped>
.fade {
  opacity: 0.1 !important;
}
</style>

<script>
import MoonLoader from 'vue-spinner/src/MoonLoader.vue';
export default {
  name: 'LoadingSpinner',
  data() {
    return {
      fade: true,
    };
  },

  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    animateBackdrop: {
      type: Boolean,
      default: false,
    },
    showBackdrop: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    MoonLoader,
  },
};
</script>
