<template>
<div>
    <loading-spinner
      v-show="loading"
      :animate-backdrop="true"
      :show-backdrop="true"/>
    <div class="row">
      <div class="col-md-12">
        <p>
          Sei un nuovo utente?
          <a
            class="empty"
            data-toggle="modal"
            data-target="#nuovo-utente"
            data-backdrop="static"
            data-keyboard="false"
            @click="nuovoUtente">
            <i class="fa fa-chevron-circle-right" /> Registrati
          </a>
          per accedere all&rsquo;area riservata.
        </p>
      </div>
    </div>
    <div class="linkSez">&nbsp;</div>
    <div class="row">
      <div class="col-md-12">
        <p>
          Username dimenticata?
          <a
            class="empty"
            data-toggle="modal"
            data-target="#nuovo-utente"
            data-backdrop="static"
            data-keyboard="false"
            @click="recuperaUsername">
            <i class="fa fa-chevron-circle-right"/> Recupera
          </a>
          la tua username.
        </p>
      </div>
    </div>
    <div class="linkSez">&nbsp;</div>
    <div class="row">
      <div class="col-md-12">
        <p>
          Password dimenticata?
          <a
            class="empty"
            data-toggle="modal"
            data-target="#nuovo-utente"
            data-backdrop="static"
            data-keyboard="false"
            @click="recuperaPassword">
            <i class="fa fa-chevron-circle-right"/> Recupera
          </a>
          la tua password.
        </p>
      </div>
    </div>
    <div class="linkSez">&nbsp;</div>
    <div
      id="nuovo-utente"
      class="modal fade"
      tabindex="-1"
      role="dialog">
      <div
        class="modal-dialog modal-lg"
        style="min-height: 50vh;"
        role="document">
        <component :is="currentView"/>
      </div>
    </div>
    <modal-view
      ref="modalErrors"
      :close-on-backdrop="false"
      :use-footer="false"
      modal-type="normal"
      header-class="modal-header-no-border modal-header-no-padding-bottom">
      <div slot="modal-header">
        <button
          slot="modal-header"
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="close"
          @click.stop="hideErrors">
          <span aria-hidden="true">&times;</span>
        </button>
        <h2 class="titolo-modal">errori</h2>
      </div>
      <template slot="modal-body">
        <ul>
          <li :key="index" v-for="(error, index) in errors">
            <strong>{{ error }}</strong>
          </li>
        </ul>
      </template>
    </modal-view>
    <modal-view
      id="modal-success"
      ref="modalSuccess"
      :close-on-backdrop="modal.closeOnBackdrop"
      :is-success="modal.success"
      :is-error="modal.isError"
      :modal-type="modal.modalType"
      :auto-close="modal.autoClose">
      <strong
        slot="modal-body"
        v-html="modal.message" />
    </modal-view>
  </div>
</template>
<style>
.section {
    font-family: "Libre Baskerville", serif;
    font-style: italic;
    text-transform: none;
    font-weight: 500;
}
</style>

<script>
/* import RegistrazioneView from "./registrazione.vue";
import RecuperaPasswordView from "./recupera-password.vue";
import RecuperaUsernameView from "./recupera-username.vue";*/
const RegistrazioneView = () => ({
  component: import('./registrazione.vue'),
  loading: LoadingSpinner,
  error: ErrorLoading
});
const RecuperaPasswordView = () => ({
  component: import('./recupera-password.vue'),
  loading: LoadingSpinner,
  error: ErrorLoading
});
const RecuperaUsernameView = () => ({
  component: import('./recupera-username.vue'),
  loading: LoadingSpinner,
  error: ErrorLoading
});
import ModalView from "../../commons/modal/modal.vue";
import LoadingSpinner from "../../commons/spinner/loading-spinner.vue";
import ErrorLoading from './error-loading.vue';
import { Loading } from 'element-ui';

let baseModel = {
    closeOnBackdrop: false,
    message: "",
    success: false,
    isError: false,
    modalType: null,
    autoClose: 0
};

export default {
    name: "LinksLoginView",

    components: {
        RegistrazioneView,
        RecuperaPasswordView,
        RecuperaUsernameView,
        ModalView,
        LoadingSpinner,
        ErrorLoading
    },

    data(){
        return {
            currentView: null,
            loading: false,

            errors: [],
            modal: Object.assign({}, baseModel)
        };
    },

    mounted(){
        this.$root.$on("errors", (err) => {
            this.showErrors(err);
        });

        this.$root.$on("hideErrors", () => this.hideErrors());

        this.$root.$on("loading", loading => {
            this.loading = loading;
        });

        this.$root.$on("dataSaved", (obj) => {
            this.showAlert(obj);
        });
    },

    methods: {
        nuovoUtente(){
            this.currentView = RegistrazioneView;
        },
        recuperaPassword(){
            this.currentView = RecuperaPasswordView;
        },
        recuperaUsername(){
            this.currentView = RecuperaUsernameView;
        },
        showErrors(errors){
            this.errors = [];
            this.errors.push(...errors);
            if (this.errors.length){
                this.$refs.modalErrors.show();
            }
        },
        hideErrors(){
            this.$refs.modalErrors.hide();
            this.errors = [];
        },

        showAlert(data){
            const _this = this;
            if (data.message){
                this.modal = Object.assign({}, this.modal, data);
                this.$root.$once("hidden::modal", () => {
                    _this.modal = Object.assign({}, baseModel);
                    if (data.cb && typeof data.cb === "function"){
                        data.cb();
                    }
                });
                this.$refs.modalSuccess.show();
            }
        }
    },

    beforeUnmount(){
        this.$root.$off("errors");
        this.$root.$off("loading");
        this.$root.$off("dataSaved");
    }
};
</script>
