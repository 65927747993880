<template>
    <div style="display:none">
        <div :id="id" :class="{ modal: true, fade: fade, in: animateModal || !fade }"
            style="display: block"
            tabindex="-1" role="dialog" @click="onClickOut($event)">
            <div class="modal-dialog" :class="{'modal-sm': modalType === 'small', 'modal-lg': modalType === 'large'}" role="document" style="z-index: 9999">
                <div class="modal-content">
                    <div class="modal-header" :class="headerClass" v-if="hasHeader">
                        <slot name="modal-header"></slot>
                    </div>
                    <div class="modal-body" :class="{ 'alert': isSuccess || isError, 'alert-success': isSuccess, 'alert-warning': isError }">
                        <slot name="modal-body"></slot>
                    </div>
                    <div class="modal-footer" v-if="hasFooter">
                        <slot name="modal-footer"></slot>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-backdrop" :class="{ fade: fade, in: animateBackdrop || !fade }"></div>
    </div>
</template>

<script>
export default {
    name: "ModalView",
    replace: true,
    computed: {
        hasHeader(){
            return !this.isSuccess && !this.isError;
        },
        hasFooter(){
            return !this.autoClose && this.useFooter;
        }
    },
    data: function(){
        return {
            animateBackdrop: false,
            animateModal: false
        };
    },

    props: {
        id: {
            type: String,
            default: "default"
        },
        fade: {
            type: Boolean,
            default: true
        },
        modalType: {
            type: String
        },
        isSuccess: {
            type: Boolean,
            default: false
        },
        isError: {
            type: Boolean,
            default: false
        },
        closeOnBackdrop: {
            type: Boolean,
            default: true
        },
        autoClose: {
            type: Number,
            default: 0
        },
        headerClass: {
            type: String
        },
        useFooter: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        show() {
            this.$el.style.display = "block";
            this._body = document.querySelector("body");
            let _this = this;

            this._modalAnimation = setTimeout(() => {
                _this.animateBackdrop = true;
                this._modalAnimation = setTimeout(() => {
                    //_this._body.classList.add("modal-open");
                    _this.animateModal = true;
                    _this.$root.$emit("shown::modal");
                    if(_this.autoClose){
                        setTimeout(() => {
                            _this.hide();
                        }, _this.autoClose);
                    }
                }, (_this.fade) ? 300 : 0);
            }, 0);
        },

        hide() {
            let _this = this;
            this.animateModal = false;
            this._modalAnimation = setTimeout(() => {
                _this.animateBackdrop = false;
                this._modalAnimation = setTimeout(() => {
                    //_this._body.classList.remove("modal-open");
                    _this.$el.style.display = "none";
                    _this.$root.$emit("hidden::modal");
                }, (_this.fade) ? 300 : 0);
            }, (_this.fade) ? 300 : 0);
        },

        onClickOut(e) {
            if(this.closeOnBackdrop && e.target.id && e.target.id === this.id){
                this.hide();
            }
        }
    },

    created: function() {
        let hub = this.$root;
        hub.$on("show::modal", (id) => id === this.id && this.show());
        hub.$on("hide::modal", (id) => id === this.id && this.hide());
    },

    mounted(){
        document.addEventListener("keydown", (e) => {
            const key = e.which || e.keyCode;
            if(key === 27) { //ESC
                this.hide();
            }
        });
    },

    destroyed(){
        clearTimeout(this._modalAnimation);
    }
}
</script>