<template>
  <div class="modal-content">
    <div class="modal-header">
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
      <div class="modal-title titolo row" style="display:flex;align-items:baseline">
        <div class="col-md-12 col-xs-12">
          <h3 class="section">errore</h3>
        </div>
      </div>
    </div>
    <div class="modal-body">
      <div class="wrapperContent">
        <p>
          Si è verificato un errore. Riprovare in un secondo momento.
        </p>
      </div>
    </div>
    <div class="modal-footer">
      <div class="row">
        <div class="col-md-offset-9 col-md-3 col-xs-12">
          <button class="btn btn-primary pull-right btn-block" data-dismiss="modal">
            <span class="text-uppercase">chiudi</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.titolo {
  font-family: "Libre Baskerville", serif;
  font-style: italic;
  font-size: 170%;
  font-weight: 400;
  margin-bottom: 0;
}
</style>

<script>
export default {
  name: 'ErrorLoading'
}
</script>
