import axios from "axios";

export default class DataApi{
    constructor(){
        this.instance = axios.create({
            baseURL: window.URLS.api,
            headers: { "__RequestVerificationToken": window.URLS.antiForgery, "X-Requested-With": "XMLHttpRequest" }
        });
    }

    getMeta(){
        return this.instance.get("/getMeta")
            .then(response => {
                if (response.data){
                    return response.data;
                }
            }).catch(() => {});
    }

    getToponimi(query){
        return axios.get(window.URLS.toponimi, {
            params: {
                q: query
            }
        }).then(response => response.data);
    }

    getInfo(step){
        return this.instance.get("/getInfo", {
            params: {
                step: step
            }
        }).then(response => response.data).catch(() => null);
    }

    save(model){
        return axios.post(window.URLS.register, model, {
            headers: { "X-Requested-With": "XMLHttpRequest" }
        }).then(response => {
            let data = response.data;
            if (data.errors){
                return Promise.reject(data.errors);
            }
            return data;
        }).catch(err => {
            if (err.message){
                return Promise.reject(err.message);
            }
            return Promise.reject(err);
        });
    }

    passwordRecovery(model){
        return axios.post(window.URLS.passwordRecovery, model, {
            headers: { "X-Requested-With": "XMLHttpRequest" }
        }).then(response => {
            let data = response.data;
            if (data.errors){
                return Promise.reject(data.errors);
            }
            return data;
        }).catch(err => {
            if (err.message){
                return Promise.reject(err.message);
            }
            return Promise.reject(err);
        });
    }

    usernameRecovery(model){
        return axios.post(window.URLS.usernameRecovery, model, {
            headers: {"X-Requested-With": "XMLHttpRequest"}
        }).then(response => {
            let data = response.data;
            if (data.errors){
                return Promise.reject(data.errors);
            }
            return data;
        }).catch(err => {
            if (err.message){
                return Promise.reject(err.message);
            }
            return Promise.reject(err);
        });
    }
}
